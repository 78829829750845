import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Flex, Text, H3, H4, H6 } from '@components/atoms'

import { companyNoInsight } from '@images/'

import { ChartDivisionBox, IncreaseTextBox } from '@components/molecules'
import {
  CompanyLikeInfoChart,
  TracksLikeTableChart,
  StreamingListenLikeCard,
} from '@components/organisms'
import { dateYMDMM, convertToPricingComma } from '@utils/format'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import CompanyStreamingListenLikeCard from '@components/organisms/Card/CompanyStreamingListenLikeCard'

const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 50px 50px 0;
`

const SectionTitle = styled(Flex)`
  flex-direction: column;
  text-align: left;
  width: 100%;

  margin-bottom: 30px;
`

const InsightCompanyTemplate = ({ onInsightTypeChange }) => {
  const [periodType, setPeriodType] = useState({
    label: '지난 28일',
    value: 'monthly',
  })

  const { authStore, trendStore } = useStore()

  const fetchCompanyTrend = trendStore?.fetchCompanyTrend || (() => {})
  const fetchCompanyTrendTrack =
    trendStore?.fetchCompanyTrendTrack || (() => {})

  const { companyTrend } = trendStore
  const { currentUser } = authStore

  const likeCountInfo = companyTrend?.likeCountInfo || null
  const listenerInfo = companyTrend?.listenerInfo || null
  const followersInfo = companyTrend?.followersInfo || null
  const rankInfo = companyTrend?.rankInfo || null

  const isNoData = companyTrend?.totalDailyList?.length < 1

  useEffect(() => {
    if (currentUser?.type === 'company' && currentUser?.companyId) {
      let __periodDays = 28
      if (periodType.value === 'daily') {
        __periodDays = 1
      }
      else if (periodType.value === 'weekly') {
        __periodDays = 7
      }
      else if (periodType.value === 'monthly') {
        __periodDays = 28
      }
      else if (periodType.value === '3months') {
        __periodDays = 90
      }
      else if (periodType.value === 'year') {
        __periodDays = 365
      }
      else if (periodType.value === 'all') {
        __periodDays = 365 * 10
      }

      fetchCompanyTrend(currentUser?.companyId, { __periodDays })
      fetchCompanyTrendTrack(currentUser?.companyId, { __periodDays })
    }
  }, [currentUser, periodType])

  if (isNoData) {
    return (
      <Flex
        type="column"
        gap="24px"
        justify="center"
        align="center"
        style={{ marginTop: 172 }}
      >
        <img
          src={companyNoInsight}
          alt="no data"
          width="188px"
          height="172px"
        />
        <H6 type="Regular">
          해당 계정과 연결된 앨범 혹은 트랙이 존재하지 않습니다.
        </H6>
      </Flex>
    )
  }
  return (
    <Flex type="column">
      <Content>
        <Flex style={{ justifyContent: 'space-between' }}>
          <Flex type="column">
            <SectionTitle>
              <H3 align="left" type="bold" style={{ marginBottom: 30 }}>
                Total Daily
              </H3>
              <Flex style={{ gap: '20px' }}>
                <Flex align="center">
                  <H4 type="bold">
                    {
                      `${convertToPricingComma(listenerInfo?.today?.total)
                      || 0} Listen`
                    }
                  </H4>
                  <IncreaseTextBox
                    data={listenerInfo?.increaseValue?.total || 0}
                    style={{ marginLeft: '12px' }}
                  />
                </Flex>
                <Flex align="center">
                  <H4 type="bold">
                    {
                      `${convertToPricingComma(likeCountInfo?.today?.total)
                      || 0} Like`
                    }
                  </H4>
                  <IncreaseTextBox
                    data={likeCountInfo?.increaseValue?.total || 0}
                    style={{ marginLeft: '12px' }}
                  />
                </Flex>
                {
                  convertToPricingComma(followersInfo?.today?.total) && (
                    <Flex align="center">
                      <H4 type="bold">
                        {
                          `${convertToPricingComma(followersInfo?.today?.total)
                        || 0} Follower`
                        }
                      </H4>
                      <IncreaseTextBox
                        data={followersInfo?.increaseValue?.total || 0}
                        style={{ marginLeft: '12px' }}
                      />
                    </Flex>
                  )
                }
              </Flex>
            </SectionTitle>
            <Flex style={{ gap: '40px' }}>
              <CompanyStreamingListenLikeCard
                streamingType="melon"
                listenData={listenerInfo?.today?.melon}
                increaseListenData={listenerInfo?.increaseValue?.melon}
                likeData={likeCountInfo?.today?.melon}
                increaseLikeData={likeCountInfo?.increaseValue?.melon}
                onClick={() => onInsightTypeChange(`melon`)}
              />
              <CompanyStreamingListenLikeCard
                streamingType="youtube"
                listenData={listenerInfo?.today?.youtube}
                increaseListenData={listenerInfo?.increaseValue?.youtube}
                likeData={likeCountInfo?.today?.youtube}
                increaseLikeData={likeCountInfo?.increaseValue?.youtube}
                onClick={() => onInsightTypeChange(`youtube`)}
              />
              <CompanyStreamingListenLikeCard
                streamingType="spotify"
                listenData={listenerInfo?.today?.spotify}
                increaseListenData={listenerInfo?.increaseValue?.spotify}
                // likeData={rankInfo?.today?.spotify}
                // increaseLikeData={rankInfo?.increaseValue?.spotify}
                onClick={() => onInsightTypeChange(`spotify`)}
              />
            </Flex>
          </Flex>
          <Flex type="column" style={{ width: '258px', margin: '20px 0' }}>
            <ChartDivisionBox
              options={
                [
                  { label: '전체', value: 'all' },
                  { label: '지난 1일', value: 'daily' },
                  { label: '지난 7일', value: 'weekly' },
                  { label: '지난 28일', value: 'monthly' },
                  { label: '지난 3개월', value: '3months' },
                  { label: '지난 1년', value: 'year' },
                ]
              }
              value={periodType}
              onChange={value => setPeriodType(value)}
            />
          </Flex>
        </Flex>
        <CompanyLikeInfoChart dailyListData={listenerInfo?.dailyList || []} />

        <Flex style={{ marginTop: '18px' }} />
        <TracksLikeTableChart
          type="company"
          onInsightTypeChange={onInsightTypeChange}
        />
      </Content>
    </Flex>
  )
}

export default observer(InsightCompanyTemplate)
