import React, { useEffect, useState } from 'react'
import { RootTemplate, InsightTrackTemplate } from '@components/templates'
import { observer } from 'mobx-react'
import { Redirect, useParams } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import qs from 'qs'

interface Props {
  match: Any;
  location: Any;
  logout: Function;
  user: Object;
}

const InsightTrackPage = ({ location, logout, user, match }: Props) => {
  const { trendStore, artistStore } = useStore()
  const params = useParams()

  const fetchArtistTrackTrend = trendStore.fetchArtistTrackTrend || (() => {})

  const artist = artistStore?.artistDetail || null
  const { artistTrackTrend } = trendStore

  const [periodType, setPeriodType] = useState({
    label: '지난 28일',
    value: 'monthly',
  })

  useEffect(() => {
    if (!artistStore.artistDetail) {
      artistStore.fetchArtistDetail(user && user['artistId'])
    }
    else {
      artistStore.fetchArtistDetail(artistStore.artistDetail._id)
    }
  }, [user, artistStore, match, periodType])

  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      // 문자열 맨 앞의 ?를 생력
    })
    if (params?.id) {
      let __periodDays = 28
      if (periodType.value === 'daily') {
        __periodDays = 1
      }
      else if (periodType.value === 'weekly') {
        __periodDays = 7
      }
      else if (periodType.value === 'monthly') {
        __periodDays = 28
      }
      else if (periodType.value === '3months') {
        __periodDays = 90
      }
      else if (periodType.value === 'year') {
        __periodDays = 365
      }
      else if (periodType.value === 'all') {
        __periodDays = 365 * 10
      }

      if (query.artistId && query.artistId !== 'null') {
        fetchArtistTrackTrend(query.artistId, params.id, { __periodDays })
      }
      else {
        // fetchArtistTrackTrend(user && user['artistId'], params.id, { __periodDays })
        fetchArtistTrackTrend(artist?._id, params.id, { __periodDays })
      }
    }
  }, [
    user,
    artist,
    params,
    artistStore,
    fetchArtistTrackTrend,
    periodType,
    location,
  ])

  return (
    <>
      <RootTemplate user={user} artist={artist} logout={logout}>
        {!(user && user['artistId']) && <Redirect to="/" />}
        <InsightTrackTemplate
          artist={artist}
          artistTrackTrend={artistTrackTrend}
          periodType={periodType}
          setPeriodType={setPeriodType}
        />
      </RootTemplate>
    </>
  )
}

export default observer(InsightTrackPage)
