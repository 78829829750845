/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react'
import {
  RootTemplate,
  NoAuthEmailTemplate,
  CompanyTemplate,
  CompanyInsightRevenueAdjacencyTemplate,
} from '@components/templates'

import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { Div, Flex } from '@components/atoms'
import { NoRevenue, RevenueUploadModal } from '@components/organisms'
import CompanyTabBar from '@components/molecules/Box/CompanyTabBar'
import styled from 'styled-components'
import InsightCompanyTotalTemplate from '@components/templates/InsightCompanyTotalTemplate'
import CompanyAlbumTemplate from '@components/templates/CompanyAlbumTemplate'
// import { defaultKakaoConsulting } from '@utils/kakao'

const CompanyPage = ({
  history,
  revenueStore,
  authStore,
  connectionsStore,
  logout,
  user,
}) => {
  const [artistList, setArtistList] = useState([])
  const [companyIdList, setCompanyIdList] = useState([])
  const [isUpdate, setIsUpdate] = useState(false)
  const [isChange, setIsChange] = useState(false)

  const [currentYear, setCurrentYear] = useState()
  const [showUpload, setShowUpload] = useState(false)

  const [trackTabBar, setTrackTabBar] = useState('revenue')

  const {
    companyRecentMonth,
    companyRevenueData,
    companyAnnualRevenueData,
    // companyTrackRevenueData,
    selectedCompanyAristId,
  } = revenueStore

  const handleMoveArtistStatement = artistId => {
    // history.push(`/statement?artistId=${artistId}`)
    window.open(`/statement?artistId=${artistId}`)
  }

  const handleMoveArtist = async artistId => {
    await authStore.artistViewMode(artistId)
    history.push(`/artist?artistId=${artistId}`)
  }
  const handleMoveAlbum = (artistId, albumId) => {
    authStore.currentUser['artistId'] = artistId
    history.push(`/insight/revenue/company/album/${albumId}`)
  }

  const tempDeleteCompanyIdList = id => {
    setIsChange(true)
    setCompanyIdList(prevState => prevState.concat(id))
    const tmpList = artistList.filter(elem => elem._id !== id)
    setArtistList(tmpList)
  }

  const _removeConnections = async () => {
    const res = await connectionsStore.removeConnections(
      'companies',
      companyIdList,
    )
    if (!res) return
    setIsUpdate(false)
  }

  const handleYear = value => {
    setCurrentYear(moment(value))
  }

  useEffect(() => {
    authStore.clearCurrentArtistId()
  }, [authStore])

  useEffect(() => {
    if (user && user._id) {
      connectionsStore.fetchartistCompanyConnectionList(user._id)
      revenueStore.fetchCompanyRecentMonth(user.companyId)
    }
  }, [user, connectionsStore, revenueStore])

  useEffect(() => {
    if (user && user._id && currentYear) {
      revenueStore.fetchRevenueCompanyArtistData(user.companyId, {
        __year: `${currentYear?.format('YY')}00`,
      })
    }
  }, [user, revenueStore, currentYear])

  useEffect(() => {
    if (user && user._id && currentYear) {
      revenueStore.fetchRevenueCompanyData(user.companyId, {
        __year: `${currentYear?.format('YY')}00`,
        __artistIdList: selectedCompanyAristId
          ? `${selectedCompanyAristId}`
          : undefined,
      })
      revenueStore.fetchRevenueCompanyAnnualData(user.companyId, {
        __year: `${currentYear?.format('YY')}00`,
        __artistIdList: selectedCompanyAristId
          ? `${selectedCompanyAristId}`
          : undefined,
      })
    }
  }, [user, revenueStore, currentYear, selectedCompanyAristId])

  useEffect(() => {
    if (!isUpdate) {
      setArtistList(connectionsStore.artistCompanyConnectionList)
    }
  }, [isUpdate, connectionsStore.artistCompanyConnectionList])

  useEffect(() => {
    if (companyRecentMonth) {
      setCurrentYear(
        moment().set({
          year: `20${Math.floor(companyRecentMonth / 100)}`,
        }),
      )
    }
  }, [companyRecentMonth])

  useEffect(() => {
    if (companyRevenueData?.revenueInfo?.companyRevenueInfo?.length === 0) {
      setTrackTabBar('insight')
    }
  }, [companyRevenueData])

  // defaultKakaoConsulting.useKakaotalkConsulting()

  return (
    <div style={{ paddingBottom: 200 }}>
      {
        user && user.isAuthEmail ? (
          <RootTemplate user={user} logout={logout}>
            <RevenueUploadModal
              show={showUpload}
              handleClose={() => setShowUpload(false)}
            />
            <Div
              display="flex"
              width="100%"
              flexDirection="column"
              paddingRight="50px"
            >
              <CompanyTemplate
                user={user}
                artistCompanyConnectionList={artistList}
                createConnectionsCompanies={
                  connectionsStore.createConnectionsCompanies
                }
                // artistViewMode={authStore.artistViewMode || (() => {})}
                artistViewMode={handleMoveArtistStatement || (() => {})}
                _removeConnections={_removeConnections}
                tempDeleteCompanyIdList={tempDeleteCompanyIdList}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                isChange={isChange}
                setIsChange={setIsChange}
              />
              <Flex type="column" style={{ padding: '0 50px' }}>
                <Line />
                <CompanyTabBar
                  activeValue={trackTabBar}
                  list={
                    [
                      { name: '음원 수익', value: 'revenue' },
                      { name: '음원 인사이트', value: 'insight' },
                      { name: '나의 앨범', value: 'album' },
                    ]
                  }
                  onSelect={item => setTrackTabBar(item?.value || 'revenue')}
                />

                {
                  trackTabBar === 'revenue' ? (
                    <>
                      {
                        companyRecentMonth === 'none' ? (
                          <NoRevenue typeToggle={() => setShowUpload(true)} />
                        ) : (
                          <CompanyInsightRevenueAdjacencyTemplate
                            companyRevenueData={companyRevenueData}
                            companyAnnualRevenueData={companyAnnualRevenueData}
                            currentYear={currentYear}
                            handleYear={handleYear}
                            handleMoveArtist={handleMoveArtist}
                            handleMoveAlbum={handleMoveAlbum}
                          />
                        )
                      }
                    </>
                  ) : trackTabBar === 'insight' ? (
                    <InsightCompanyTotalTemplate />
                  ) : (
                    <CompanyAlbumTemplate />
                  )
                }
              </Flex>
            </Div>
          </RootTemplate>
        ) : (
          <NoAuthEmailTemplate user={user} emailAuth={authStore.emailAuth} />
        )
      }
      {/* <defaultKakaoConsulting.KakaotalkConsulting /> */}
    </div>
  )
}

export default inject(
  'authStore',
  'revenueStore',
  'artistStore',
  'statsStore',
  'connectionsStore',
  'albumStore',
)(observer(CompanyPage))

const Line = styled.div`
  /* width: '100%'; */
  display: flex;
  height: 1px;
  margin: 50px 0;
  justify-content: space-between;
  align-items: center;
  border-top: solid 0.5px #d4d4d4;
`
