import React from 'react'
import styled from 'styled-components'
import { Flex, Text, H3, H5, H4, Container, H6 } from '@components/atoms'

import { ChartDivisionBox, IncreaseTextBox } from '@components/molecules'
import {
  TrackLikeInfoChart,
  DailyTrackLikeTableChart,
  StreamingListenLikeCard,
} from '@components/organisms'
import { dateYMDMM, convertToPricingComma } from '@utils/format'
import { STORAGE_URL } from '@consts/'
import { useHistory } from 'react-router-dom'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 50px 50px 0;
`

const SectionTitle = styled(Flex)`
  flex-direction: column;
  text-align: left;
  width: 100%;

  margin-bottom: 30px;
`

const MusicInfo = styled(Flex)`
  height: 160px;
  margin: 0 0 30px 0;

  img {
    width: 160px;
    height: 160px;
    border-radius: 6px;
    margin-right: 30px;
  }
`

export const InsightTrackTemplate = ({
  artist,
  artistTrackTrend,
  periodType,
  setPeriodType,
}) => {
  const history = useHistory()

  const albumInfo = artistTrackTrend?.albumInfo || null
  const rankInfo = artistTrackTrend?.rankInfo || null
  const likeCountInfo = artistTrackTrend?.likeCountInfo || null
  const listenerInfo = artistTrackTrend?.listenerInfo || null
  return (
    <Container>
      <Header>
        <Text type="Medium" size="18px" style={{ marginTop: '10px' }}>
          트랙
        </Text>
        <Text
          type="Medium"
          color="#949494"
          size="14px"
          style={{ margin: '10px 10px 0 0' }}
        >
          {
            artist && artist.nowLikeCrawling
              ? '정보를 업데이트 중 입니다.'
              : `${artistTrackTrend
                && dateYMDMM(artistTrackTrend.trendDataAddedAt)} 업데이트`
          }
        </Text>
      </Header>
      <Content>
        <Flex style={{ justifyContent: 'space-between' }}>
          <Flex type="column">
            <MusicInfo>
              <img
                src={STORAGE_URL + albumInfo?.image512Path}
                alt="album_img"
              />
              <Flex type="column">
                <H4 align="left" color="#949494">
                  {albumInfo?.title}
                </H4>
                <H3 align="left" style={{ width: '880px' }}>
                  {artistTrackTrend?.title}
                </H3>
                <Flex style={{ marginTop: 4 }}>
                  <H6>{`권리사 : ${albumInfo?.planningCompanyList[0]?.name}`}</H6>
                  <H6>
                    &nbsp;&nbsp;&nbsp;
                    {`유통사 : ${albumInfo?.releaseCompanyList[0]?.name}`}
                  </H6>
                </Flex>
              </Flex>
            </MusicInfo>
            <SectionTitle>
              <H5 align="left" color="#949494">
                Total
              </H5>
              <Flex style={{ gap: '20px' }}>
                <Flex align="center">
                  <H4 type="bold">
                    {
                      `${convertToPricingComma(listenerInfo?.today?.total)
                      || 0} Listen`
                    }
                  </H4>
                  <IncreaseTextBox
                    data={listenerInfo?.increaseValue?.total || 0}
                    style={{ marginLeft: '12px' }}
                  />
                </Flex>
                <Flex align="center">
                  <H4 type="bold">
                    {
                      `${convertToPricingComma(likeCountInfo?.today?.total)
                      || 0} Like`
                    }
                  </H4>
                  <IncreaseTextBox
                    data={likeCountInfo?.increaseValue?.total || 0}
                    style={{ marginLeft: '12px' }}
                  />
                </Flex>
              </Flex>
            </SectionTitle>
            <Flex style={{ gap: '40px' }}>
              <a href={artistTrackTrend?.melonUrl}>
                <StreamingListenLikeCard
                  streamingType="melon"
                  listenData={listenerInfo?.today?.melon}
                  increaseListenData={listenerInfo?.increaseValue?.melon}
                  likeData={likeCountInfo?.today?.melon}
                  increaseLikeData={likeCountInfo?.increaseValue?.melon}
                />
              </a>
              <a href={artistTrackTrend?.youtubeUrl}>
                <StreamingListenLikeCard
                  streamingType="youtube"
                  listenData={listenerInfo?.today?.youtube}
                  increaseListenData={listenerInfo?.increaseValue?.youtube}
                  likeData={likeCountInfo?.today?.youtube}
                  increaseLikeData={likeCountInfo?.increaseValue?.youtube}
                  onClick={
                    () =>
                      history.push(
                        `/insight/artist/track/youtube/${artistTrackTrend._id}`,
                      )
                  }
                />
              </a>

              <StreamingListenLikeCard
                streamingType="spotify"
                listenData={listenerInfo?.today?.spotify}
                increaseListenData={listenerInfo?.increaseValue?.spotify}
                likeData={rankInfo?.today?.spotify}
                increaseLikeData={rankInfo?.increaseValue?.spotify}
                onClick={() => history.push(`/insight/artist/sns/spotify`)}
              />
            </Flex>
          </Flex>
          <Flex type="column" style={{ width: '258px', margin: '40px 0 0 0' }}>
            <ChartDivisionBox
              options={
                [
                  { label: '전체', value: 'all' },
                  { label: '지난 1일', value: 'daily' },
                  { label: '지난 7일', value: 'weekly' },
                  { label: '지난 28일', value: 'monthly' },
                  { label: '지난 3개월', value: '3months' },
                  { label: '지난 1년', value: 'year' },
                ]
              }
              value={periodType}
              onChange={value => setPeriodType(value)}
            />
          </Flex>
        </Flex>
        <TrackLikeInfoChart
          dailyListData={artistTrackTrend?.listenerInfo?.dailyList || []}
        />
        <DailyTrackLikeTableChart
          tableData={artistTrackTrend?.dailyDetailList || []}
        />
      </Content>
    </Container>
  )
}
