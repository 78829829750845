import React, { useEffect, useState } from 'react'
import { RootTemplate, InsightArtistSnsTemplate } from '@components/templates'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import { InsightArtistSnsSubTemplate } from '@components/templates/InsightArtistSnsSubTemplate'
import { InsightArtistSpotifyTemplate } from '@components/templates/InsightArtistSpotifyTemplate'

interface Props {
  match: Any;
  location: Any;
  logout: Function;
  user: Object;
}

const InsightArtistSnsPage = ({ logout, user, match }: Props) => {
  const { artistStore, trendStore } = useStore()

  const artist = artistStore.artistDetail
  const { artistTrend } = trendStore

  const fetchArtistDetail = artistStore.fetchArtistDetail || (() => {})
  const fetchArtistTrend = trendStore.fetchArtistTrend || (() => {})
  const fetchArtistTrendTrack = trendStore.fetchArtistTrendTrack || (() => {})

  const [periodType, setPeriodType] = useState({
    label: '지난 28일',
    value: 'monthly',
  })

  useEffect(() => {
    if (!artistStore.artistDetail) {
      fetchArtistDetail(user && user['artistId'])
    }
    else {
      fetchArtistDetail(artistStore.artistDetail._id)
    }
  }, [user, artistStore, match, fetchArtistDetail, periodType])

  useEffect(() => {
    if (artist) {
      let __periodDays = 28
      if (periodType.value === 'daily') {
        __periodDays = 1
      }
      else if (periodType.value === 'weekly') {
        __periodDays = 7
      }
      else if (periodType.value === 'monthly') {
        __periodDays = 28
      }
      else if (periodType.value === '3months') {
        __periodDays = 90
      }
      else if (periodType.value === 'year') {
        __periodDays = 365
      }
      else if (periodType.value === 'all') {
        __periodDays = 365 * 10
      }

      fetchArtistTrend(artist?._id, { __periodDays })
      fetchArtistTrendTrack(artist?._id, { __periodDays })
    }
  }, [periodType, artist, fetchArtistTrend, fetchArtistTrendTrack])

  return (
    <>
      <RootTemplate user={user} artist={artist} logout={logout}>
        {!(user && user['artistId']) && <Redirect to="/" />}
        {
          match.params.snsType === 'spotify' ? (
            <InsightArtistSpotifyTemplate
              artist={artist}
              artistTrend={artistTrend}
              periodType={periodType}
              setPeriodType={setPeriodType}
              snsType={match.params.snsType}
            />
          ) : match.params.snsType === 'youtube'
          || match.params.snsType === 'melon' ? (
              <InsightArtistSnsTemplate
                artist={artist}
                artistTrend={artistTrend}
                periodType={periodType}
                setPeriodType={setPeriodType}
                snsType={match.params.snsType}
              />
            ) : (
              <InsightArtistSnsSubTemplate
                artist={artist}
                artistTrend={artistTrend}
                periodType={periodType}
                setPeriodType={setPeriodType}
                snsType={match.params.snsType}
              />
            )
        }
      </RootTemplate>
    </>
  )
}

export default observer(InsightArtistSnsPage)
