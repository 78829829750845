import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Text, H3, Container, H6, H4, Caption1 } from '@components/atoms'

import { ChartDivisionBox, IncreaseTextBox } from '@components/molecules'
import {
  ArtistSnsFollowerChart,
  DailySnsFollowerTableChart,
  TracksSnsLikeTableChart,
} from '@components/organisms'
import { dateYMDMM, convertToPricingComma, dateYMD } from '@utils/format'

import {
  copyGrayImg,
  melonSnsImg,
  instagram_follower_img,
  twitter_follower_img,
  soundcloud_follower_img,
  youtube_follower_img,
  spotifySnsImg,
} from '@images/'
import { useLocation } from 'react-router-dom'
import TracksYoutubeLikeTableChart from '@components/organisms/Chart/TracksYoutubeLikeTableChart'
import DailyYoutubeFollowerTableChart from '@components/organisms/Chart/DailyYoutubeFollowerTableChart'
import DailySpotifyFollowerTableChart from '@components/organisms/Chart/DailySpotifyFollowerTableChart'
import TracksSpotifyLikeTableChart from '@components/organisms/Chart/TracksSpotifyLikeTableChart'
import { YoutubeListenTooltip } from '@components/organisms/Tooltip'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 50px 50px 0;
`

const SectionTitle = styled(Flex)`
  flex-direction: column;
  text-align: left;
  width: 100%;

  margin-bottom: 30px;
`

const SnsInfo = styled(Flex)`
  align-items: center;
  height: 48px;
  margin: 0 0 30px 0;
  gap: 4px;

  img {
    width: 48px;
    height: 48px;
  }
`

export const InsightArtistSnsTemplate = ({
  artist,
  artistTrend,
  periodType,
  setPeriodType,
  snsType,
}) => {
  const likeCountInfo = artistTrend?.likeCountInfo || null
  const listenerInfo = artistTrend?.listenerInfo || null
  const followersInfo = artistTrend?.followersInfo || null
  const totalDailyList = artistTrend?.totalDailyList || []
  const isNowCrawling = artistTrend?.isNowCrawling || false

  const location = useLocation()

  return (
    <>
      <Container>
        <Header>
          <Text
            type="Medium"
            size="18px"
            style={{ marginTop: '10px', textTransform: 'capitalize' }}
          >
            {snsType}
          </Text>
          <Text
            type="Medium"
            color="#949494"
            size="14px"
            style={{ margin: '10px 50px 0 0' }}
          >
            {
              artist && artist.nowLikeCrawling
                ? '정보를 업데이트 중 입니다.'
                : artistTrend && artistTrend.trendDataAddedAt
                  ? `${artistTrend
                  && dateYMDMM(artistTrend.trendDataAddedAt)} 업데이트`
                  : '업데이트할 데이터가 없습니다.'
            }
          </Text>
        </Header>
        <Content>
          <Flex style={{ justifyContent: 'space-between' }}>
            <Flex type="column">
              <SnsInfo>
                <img
                  src={
                    snsType === 'melon'
                      ? melonSnsImg
                      : snsType === 'instagram'
                        ? instagram_follower_img
                        : snsType === 'youtube'
                          ? youtube_follower_img
                          : snsType === 'twitter'
                            ? twitter_follower_img
                            : snsType === 'soundcloud'
                              ? soundcloud_follower_img
                              : snsType === 'spotify'
                                ? spotifySnsImg
                                : false
                  }
                  alt="sns_img"
                />
                <H3
                  align="left"
                  style={
                    {
                      textTransform: 'capitalize',
                    }
                  }
                >
                  {snsType}
                </H3>
              </SnsInfo>
              <SectionTitle>
                <Flex
                  style={{ marginBottom: 6, gap: 10, alignItems: 'flex-end' }}
                >
                  <H6 align="left" color="#949494">
                    {`Total Daily`}
                  </H6>
                  <Caption1 align="left" color="#949494">
                    {
artistTrend?.trendDataAddedAt
  ? `*${dateYMD(artistTrend?.trendDataAddedAt)}`
  : ''
                    }
                  </Caption1>
                </Flex>
                <Flex style={{ gap: '20px' }}>
                  <Flex align="center">
                    <Flex
                      align="center"
                      gap="4px"
                      style={{ position: 'relative' }}
                    >
                      <H4 type="bold">
                        {
                          `${convertToPricingComma(
                          listenerInfo?.today[snsType],
                          ) || 0} Listen`
                        }
                      </H4>
                      {snsType === 'youtube' && <YoutubeListenTooltip />}
                    </Flex>
                    <IncreaseTextBox
                      data={listenerInfo?.increaseValue[snsType] || 0}
                      style={{ marginLeft: '12px' }}
                    />
                  </Flex>
                  <Flex align="center">
                    <H4 type="bold">
                      {
                        `${convertToPricingComma(
                        likeCountInfo?.today[snsType],
                        ) || 0} Like`
                      }
                    </H4>
                    <IncreaseTextBox
                      data={likeCountInfo?.increaseValue[snsType] || 0}
                      style={{ marginLeft: '12px' }}
                    />
                  </Flex>
                  <Flex align="center">
                    <H4 type="bold">
                      {
                        `${convertToPricingComma(
                        followersInfo?.today && followersInfo?.today[snsType],
                        ) || 0} Follower`
                      }
                    </H4>
                    <IncreaseTextBox
                      data={
                        (followersInfo?.increaseValue
                          && followersInfo?.increaseValue[snsType])
                        || 0
                      }
                      style={{ marginLeft: '12px' }}
                    />
                  </Flex>
                </Flex>
              </SectionTitle>
            </Flex>
            <Flex align="center" style={{ gap: 30, height: 50 }}>
              {
                ((snsType === 'youtube' && artist?.youtubeUrl)
                || snsType !== 'youtube') && (
                  <Flex
                    justify="center"
                    align="center"
                    style={
                      {
                        width: 28,
                        height: 28,
                        borderRadius: '50%',
                        backgroundColor: '#f4f4f4',
                        cursor: 'pointer',
                      }
                    }
                    onClick={
                      () => {
                        let url = window.document.location.host + location.pathname
                        switch (snsType) {
                          case 'melon':
                            url = artist.melonUrl
                            break
                          case 'instagram':
                            url = artist.instagramUrl
                            break
                          case 'youtube':
                            url = artist.youtubeUrl
                            break
                          case 'bugs':
                            url = artist.bugsUrl
                            break
                          case 'genie':
                            url = artist.genieUrl
                            break
                          case 'twitter':
                            url = artist.twitterUrl
                            break
                          case 'soundcloud':
                            url = artist.soundcloudUrl
                            break
                          case 'spotify':
                            url = artist.spotifyUrl
                            break
                          default:
                            break
                        }
                        window.open(url)
                      }
                    }
                  >
                    <img src={copyGrayImg} width={20} height={20} alt="copy" />
                  </Flex>
                )
              }
              <div style={{ width: '258px' }}>
                <ChartDivisionBox
                  options={
                    [
                      { label: '전체', value: 'all' },
                      { label: '지난 1일', value: 'daily' },
                      { label: '지난 7일', value: 'weekly' },
                      { label: '지난 28일', value: 'monthly' },
                      { label: '지난 3개월', value: '3months' },
                      { label: '지난 1년', value: 'year' },
                    ]
                  }
                  value={periodType}
                  onChange={value => setPeriodType(value)}
                />
              </div>
            </Flex>
          </Flex>
          <Flex style={{ gap: 20, alignItems: 'flex-start' }}>
            <ArtistSnsFollowerChart
              dailyListData={totalDailyList}
              snsType={snsType}
              isNowCrawling={isNowCrawling}
            />
            {
              snsType === 'spotify' ? (
                <DailySpotifyFollowerTableChart
                  data={totalDailyList}
                  snsType="spotify"
                />
              ) : snsType === 'youtube' ? (
                <DailyYoutubeFollowerTableChart
                  data={totalDailyList}
                  snsType="youtube"
                />
              ) : (
                <DailySnsFollowerTableChart
                  data={totalDailyList}
                  snsType={snsType}
                />
              )
            }
          </Flex>
          {
            snsType === 'spotify' ? (
              <TracksSpotifyLikeTableChart snsType="spotify" />
            ) : snsType === 'youtube' ? (
              <TracksYoutubeLikeTableChart snsType="youtube" />
            ) : (
              <TracksSnsLikeTableChart snsType={snsType} />
            )
          }
        </Content>
      </Container>
    </>
  )
}
