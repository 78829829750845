import React from 'react'
import styled from 'styled-components'
import { Flex, Text, H3, Container, H4, Caption2 } from '@components/atoms'

import { ChartDivisionBox, IncreaseTextBox } from '@components/molecules'
import {
  ArtistLikeInfoChart,
  TracksLikeTableChart,
  SnsFollowerCard,
  StreamingListenLikeCard,
} from '@components/organisms'
import { dateYMDMM, convertToPricingComma } from '@utils/format'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 50px 50px 0;
`

const SectionTitle = styled(Flex)`
  flex-direction: column;
  text-align: left;
  width: 100%;

  margin-bottom: 30px;
`

export const InsightArtistTemplate = ({
  artist,
  artistTrend,
  periodType,
  setPeriodType,
}) => {
  const history = useHistory()

  const likeCountInfo = artistTrend?.likeCountInfo || null
  const listenerInfo = artistTrend?.listenerInfo || null
  const followersInfo = artistTrend?.followersInfo || null
  const rankInfo = artistTrend?.rankInfo || null
  const isNowCrawling = artistTrend?.isNowCrawling || false

  const handleSnsCardClick = (type, exist) => {
    if (!exist) {
      history.push(`/artist?artistId=${artist?._id}&isOpenUpdate=1`)
    }
    else {
      history.push(`/insight/artist/sns/${type}`)
    }
  }

  return (
    <Container>
      <Header>
        <Text type="Medium" size="18px" style={{ marginTop: '10px' }}>
          아티스트
        </Text>
        <Text
          type="Medium"
          color="#949494"
          size="14px"
          style={{ margin: '10px 50px 0 0' }}
        >
          {
            artist && artist.nowLikeCrawling
              ? '정보를 업데이트 중 입니다.'
              : artistTrend && artistTrend.trendDataAddedAt
                ? `${artistTrend
                && dateYMDMM(artistTrend.trendDataAddedAt)} 업데이트`
                : '업데이트할 데이터가 없습니다.'
          }
        </Text>
      </Header>
      <Content>
        <Flex style={{ justifyContent: 'space-between' }}>
          <Flex type="column">
            <SectionTitle>
              <Flex align="center" gap="16px">
                <H3 align="left" type="bold" style={{ marginBottom: 30 }}>
                  Total Dail
                </H3>
                {
                  !!artistTrend?.trendDataAddedAt && (
                    <Caption2 color="#949494">
                      {
                        moment(artistTrend?.trendDataAddedAt).format(
                          'YYYY년 MM월 DD일',
                        )
                      }
                    </Caption2>
                  )
                }
              </Flex>
              <Flex style={{ gap: '20px' }}>
                <Flex align="center">
                  <H4 type="bold">
                    {
                      `${convertToPricingComma(listenerInfo?.today?.total)
                      || 0} Listen`
                    }
                  </H4>
                  <IncreaseTextBox
                    data={listenerInfo?.increaseValue?.total || 0}
                    style={{ marginLeft: '12px' }}
                  />
                </Flex>
                <Flex align="center">
                  <H4 type="bold">
                    {
                      `${convertToPricingComma(likeCountInfo?.today?.total)
                      || 0} Like`
                    }
                  </H4>
                  <IncreaseTextBox
                    data={likeCountInfo?.increaseValue?.total || 0}
                    style={{ marginLeft: '12px' }}
                  />
                </Flex>
                <Flex align="center">
                  <H4 type="bold">
                    {
                      `${convertToPricingComma(followersInfo?.today?.total)
                      || 0} Follower`
                    }
                  </H4>
                  <IncreaseTextBox
                    data={followersInfo?.increaseValue?.total || 0}
                    style={{ marginLeft: '12px' }}
                  />
                </Flex>
              </Flex>
            </SectionTitle>
            <Flex style={{ gap: '40px' }}>
              <StreamingListenLikeCard
                streamingType="melon"
                listenData={listenerInfo?.today?.melon}
                increaseListenData={listenerInfo?.increaseValue?.melon}
                likeData={likeCountInfo?.today?.melon}
                increaseLikeData={likeCountInfo?.increaseValue?.melon}
                onClick={() => history.push(`/insight/artist/sns/melon`)}
              />
              <StreamingListenLikeCard
                streamingType="youtube"
                listenData={listenerInfo?.today?.youtube}
                increaseListenData={listenerInfo?.increaseValue?.youtube}
                likeData={likeCountInfo?.today?.youtube}
                increaseLikeData={likeCountInfo?.increaseValue?.youtube}
                onClick={() => history.push(`/insight/artist/sns/youtube`)}
              />
              <StreamingListenLikeCard
                streamingType="spotify"
                listenData={listenerInfo?.today?.spotify}
                increaseListenData={listenerInfo?.increaseValue?.spotify}
                likeData={rankInfo?.today?.spotify}
                increaseLikeData={rankInfo?.increaseValue?.spotify}
                onClick={() => history.push(`/insight/artist/sns/spotify`)}
              />
            </Flex>
          </Flex>
          <Flex type="column" style={{ width: '258px', margin: '20px 0' }}>
            <ChartDivisionBox
              options={
                [
                  { label: '전체', value: 'all' },
                  { label: '지난 1일', value: 'daily' },
                  { label: '지난 7일', value: 'weekly' },
                  { label: '지난 28일', value: 'monthly' },
                  { label: '지난 3개월', value: '3months' },
                  { label: '지난 1년', value: 'year' },
                ]
              }
              value={periodType}
              onChange={value => setPeriodType(value)}
            />
          </Flex>
        </Flex>
        <ArtistLikeInfoChart
          dailyListData={listenerInfo?.dailyList || []}
          isNowCrawling={isNowCrawling}
        />

        <Flex style={{ marginTop: '18px' }}>
          <SnsFollowerCard
            snsType="melon"
            followerData={followersInfo?.today?.melon}
            increaseFollowerData={followersInfo?.today?.increaseValue?.melon}
            unit="명"
            link={artistTrend?.melonUrl}
            onClick={() => handleSnsCardClick('melon', artistTrend?.melonUrl)}
          />
          <SnsFollowerCard
            snsType="youtube"
            followerData={followersInfo?.today?.youtube}
            increaseFollowerData={followersInfo?.today?.increaseValue?.youtube}
            unit="명"
            link={artistTrend?.youtubeUrl}
            onClick={
              () =>
                handleSnsCardClick('youtube', artistTrend?.youtubeUrl)
            }
          />
          <SnsFollowerCard
            snsType="spotify"
            followerData={followersInfo?.today?.spotify}
            increaseFollowerData={followersInfo?.today?.increaseValue?.spotify}
            unit="명"
            link={artistTrend?.spotifyUrl}
            onClick={
              () =>
                handleSnsCardClick('spotify', artistTrend?.spotifyUrl)
            }
          />
          <SnsFollowerCard
            snsType="genie"
            followerData={followersInfo?.today?.genie}
            increaseFollowerData={followersInfo?.today?.increaseValue?.genie}
            unit="명"
            link={artistTrend?.genieUrl}
            onClick={() => handleSnsCardClick('genie', artistTrend?.genieUrl)}
          />
          <SnsFollowerCard
            snsType="bugs"
            followerData={followersInfo?.today?.bugs}
            increaseFollowerData={followersInfo?.today?.increaseValue?.bugs}
            unit="명"
            link={artistTrend?.bugsUrl}
            onClick={() => handleSnsCardClick('bugs', artistTrend?.bugsUrl)}
          />
          {/* <SnsFollowerCard
            snsType="instagram"
            followerData={followersInfo?.today?.instagram}
            increaseFollowerData={
              followersInfo?.today?.increaseValue?.instagram
            }
            unit="명"
            link={artistTrend?.instagramUrl}
            onClick={() =>
              handleSnsCardClick('instagram', artistTrend?.instagramUrl)
            }
          /> */}
          <SnsFollowerCard
            snsType="x(twitter)"
            followerData={followersInfo?.today?.twitter}
            increaseFollowerData={followersInfo?.today?.increaseValue?.twitter}
            unit="명"
            link={artistTrend?.twitterUrl}
            onClick={
              () =>
                handleSnsCardClick('twitter', artistTrend?.twitterUrl)
            }
          />
          <SnsFollowerCard
            snsType="soundcloud"
            followerData={followersInfo?.today?.soundcloud}
            increaseFollowerData={
              followersInfo?.today?.increaseValue?.soundcloud
            }
            unit="명"
            link={artistTrend?.soundcloudUrl}
            onClick={
              () =>
                handleSnsCardClick('soundcloud', artistTrend?.soundcloudUrl)
            }
            isLast
          />
        </Flex>

        <TracksLikeTableChart />
      </Content>
    </Container>
  )
}
